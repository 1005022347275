<template>
<v-container lighten-5>
    <v-row>
        <v-col cols="auto" md="12">
            <v-data-table :headers="headers" dense :items="lista" :page.sync="page" :items-per-page="10" hide-default-footer sort-desc sort-by="cbdpId" class="elevation-1">
                <template v-slot:top>

                    <v-toolbar>
                        <v-toolbar-title class="primary--text">Pacientes</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-btn class="mx-2" tile color="primary" @click.stop="dialog = true" v-if="getPermiso('crearBasedatospacientes')">
                            agregar
                        </v-btn>
                        <div class="flex-grow-1"></div>
                    </v-toolbar>
                    <Dfiltros :onBuscar="buscar" v-bind:filtros="filtros" />
                </template>
                <template v-slot:[`item.coppId`]="{ item }">
                    <span class="primary--text">{{ item.tblConfPersonaspaciente.tblConfPersonasnaturale.copnNombre1  + " " +item.tblConfPersonaspaciente.tblConfPersonasnaturale.copnNombre2+ " " +item.tblConfPersonaspaciente.tblConfPersonasnaturale.copnApellido1  + " " +item.tblConfPersonaspaciente.tblConfPersonasnaturale.copnApellido2 }}
                    </span> - <span class="grey--text">{{ item.tblConfPersonaspaciente.tblConfPersonasnaturale.tblConfPersona.copeIdentificacion}}</span>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn fab x-small icon color="success" v-on="on" @click="trasladar(item)" v-if="getPermiso('crearBasedatospacientes')">
                                <v-icon>mdi-transit-transfer</v-icon>
                            </v-btn>
                        </template>
                        <span>Traslado</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn fab x-small icon color="info" v-on="on" @click="editar(item)" v-if="getPermiso('crearBasedatospacientes')">
                                <v-icon>edit</v-icon>
                            </v-btn>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn fab x-small icon color="error" v-on="on" @click="eliminar(item.cbdpId)" v-if="getPermiso('crearBasedatospacientes')">
                                <v-icon>delete</v-icon>
                            </v-btn>
                        </template>
                        <span>Eliminar</span>
                    </v-tooltip>
                </template>
                <template v-slot:no-data>
                    <label class="primary--text">No hay datos disponibles</label>
                </template>
            </v-data-table>
            <div class="text-center pt-2" v-if="pageCount > 0">
                <span>total resultados: {{(Count)}}</span>
                <v-pagination circle v-model="page" @input="buscar(filtros)" @next="buscar(filtros)" @previous="buscar(filtros)" :total-visible="10" :length="pageCount"></v-pagination>

            </div>
        </v-col>
    </v-row>

    <v-form @submit.prevent="agregarPaciente">
        <v-dialog v-model="dialog" persistent width="800px" max-width="800px">
            <v-card>
                <v-card-title>
                    <span class="headline">Agregar paciente a base de datos</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-autocomplete outlined :error-messages="errores['coppId']" v-model="form.coppId" :items="pacientes.pacientes" :loading="pacientes.isLoading" :search-input.sync="pacientes.search" @keypress.enter="searchPaciente" no-filter hide-no-data hide-selected item-text="tblConfPersonasnaturales[0].copnNombre1" item-value="tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppId" item-color="primary" label="Paciente" clearable>
                                    <template v-slot:selection="data">
                                        {{data.item.tblConfPersonasnaturales[0].copnNombre1 + " "+data.item.tblConfPersonasnaturales[0].copnNombre2 + " "+data.item.tblConfPersonasnaturales[0].copnApellido1 + " "+data.item.tblConfPersonasnaturales[0].copnApellido2}}
                                    </template>
                                    <template v-slot:item="data">
                                        <template v-if="(typeof data.item !== 'object')">
                                            <v-list-item-content v-text="data.item"></v-list-item-content>
                                        </template>
                                        <template v-else>
                                            <v-list-item-content>
                                                <v-list-item-title v-html="data.item.tblConfPersonasnaturales[0].copnNombre1 + ' '+data.item.tblConfPersonasnaturales[0].copnNombre2 + ' '+data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' +data.item.tblConfPersonasnaturales[0].copnApellido2"></v-list-item-title>
                                                <v-list-item-subtitle v-html="data.item.copeIdentificacion">
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                <v-autocomplete outlined :error-messages="errores['cbdpTipoEstado']" v-model="form.cbdpTipoEstado" :items="tipoestado" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Estado" clearable>
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>

                    <v-btn color="primary darken-1" text @click="close">Cancelar</v-btn>
                    <div class="flex-grow-1"></div>
                    <v-btn color="primary darken-1" dark @click="agregarPaciente">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-form>
    <v-form @submit.prevent="trasladarPaciente">
        <v-dialog v-model="dialog2" persistent width="800px" max-width="800px">
            <v-card>
                <v-card-title>
                    <span class="headline">Trasladar paciente a otra base de datos</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-autocomplete outlined :error-messages="errores['cobdId']" v-model="formTraslado.cobdId" :items="basededatos"  hide-selected item-text="cobdNombre" item-value="cobdId" item-color="primary" label="Base de datos" clearable>
                                    <template v-slot:selection="data">
                                        {{data.item.tblConfContratos[0].cocoNombre + " "+data.item.anio.comaNombrelargo + "-"+data.item.mes.comaNombrelargo + "-"+data.item.regimen.comaNombrelargo}}
                                    </template>
                                    <template v-slot:item="data">
                                        <template v-if="(typeof data.item !== 'object')">
                                            <v-list-item-content v-text="data.item"></v-list-item-content>
                                        </template>
                                        <template v-else>
                                            <v-list-item-content>
                                                <v-list-item-title v-html="data.item.tblConfContratos[0].cocoNombre + ' '+data.item.anio.comaNombrelargo + '-'+data.item.mes.comaNombrelargo + '-'+data.item.regimen.comaNombrelargo"></v-list-item-title>
                                            </v-list-item-content>
                                        </template>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn color="primary darken-1" text @click="dialog2=!dialog2">Cancelar</v-btn>
                    <div class="flex-grow-1"></div>
                    <v-btn color="primary darken-1" dark @click="trasladarPaciente">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-form>
</v-container>
</template>

<script>
import generales from '../../../mixins/generales.js';
export default {
    name: 'BasedatosPacientes',
    props: {
        cobdId: Number,
    },
    mixins: [generales],
    data() {
        return {
            url: "cita/basedatospacientes/",
            form: {
                cbdpTipoEstado: null,
                coppId: null,
                cobdId: null,
            },
            formTraslado:{
                cbdpId:null,
                cobdId: null,
            },
            filtros: [

                {
                    text: "Paciente",
                    value: null,
                    tipo: "lista",
                    /*si es tipo lista descomentar esta parte*/
                    lista: [],
                    listatext: "tblConfPersonasnaturales[0].copnNombre1",
                    listavalue: "tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppId",
                    buscar: 'paciente',
                    isLoading: false,
                    search: null,
                    //filter: (item, queryText, itemText) => (item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + item.tblConfPersonasnaturales[0].copnApellido2).indexOf(queryText.toUpperCase()) > -1 || item.copeIdentificacion.indexOf(queryText) > -1,
                    default: true,
                    defaultselect: (data) => {
                        return data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2
                    },
                    defaultbody: (data) => {
                        return {
                            title: data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2,
                            subtitle: data.item.copeIdentificacion
                        }
                    },
                },
                {
                    text: "Estado",
                    value: null,
                    tipo: "lista",
                    /*si es tipo lista descomentar esta parte*/
                    lista: [],
                    listatext: "comaNombrecorto",
                    listavalue: "comaId",
                },

            ],
            headers: [{
                    text: "Paciente",
                    value: "coppId"
                },
                {
                    text: "Estado",
                    value: "estado.comaNombrecorto"
                },
                {
                    text: "Acciones",
                    value: "action",
                    sortable: false
                }
            ],
            tipoestado: [],
            pacientes: {
                search: null,
                isLoading: false,
                pacientes: []
            }
        };
    },
    created: async function () {
        await this.buscar(this.filtros);
        this.filtros[1].lista = this.tipoestado = await this.$apiService.index("sistema/maestra/index/TBL_TIPOESTADO").then(data => data);
        this.basededatos = await this.$apiService.index(`sistema/basededatos/index`).then(data => data);
        this.limpiar();
    },

    methods: {
        async searchPaciente() {
            this.pacientes.isLoading = true;

            if (this.pacientes.search.length > 0 && this.pacientes.search.toString().replace(/ /g, "") != "") {
                this.$apiService.index("sistema/personas/buscar2/pacientes/" + this.pacientes.search.toUpperCase()).then(data => {
                    this.pacientes.isLoading = false;
                    this.pacientes.pacientes = data;
                });
            }

        },
        async search(searchP) {

            this.$store.commit('setCargaDatos', true);

            let data = null;
            data = await this.$apiService.index(`${this.url}index/${this.cobdId}?${searchP}`).then(data => data);

            if (data) {
                this.lista = this.listaCopia = data.rows;
                this.Count = data.count;
                this.pageCount = Math.ceil((data.count) / 10);
            }
            this.$store.commit('setCargaDatos', false);

        },

        async buscar(filtros) {
            this.filtros = filtros;
            this.lista = this.listaCopia;
            var search = `offset=${(this.page-1)*10}&`;

            if (this.filtros[0].value != null && this.filtros[0].value.toString().replace(/ /g, "") != "") {
                search = search + `coppId=${this.filtros[0].value}&`;

            }
            if (this.filtros[1].value != null && this.filtros[1].value.toString().replace(/ /g, "") != "") {
                search = search + `cbdpTipoEstado=${this.filtros[1].value}&`;

            }

            if (search != "") {
                await this.search(search);
            }

        },
        async agregarPaciente() {
            try {
                this.errores = [];
                this.loadingGuardar = true;
                let data = this.isNew ? await this.$apiService.create("cita/basedatospacientes/create", this.form).then(data => data):await this.$apiService.update("cita/basedatospacientes/update/"+this.form.cbdpId, this.form).then(data => data);
                if (data) {
                        this.$swal.fire({
                            title: "Completado!",
                            text: "Registro exitoso",
                            icon: "success",
                            confirmButtonText: "Ok"
                        });
                        await this.buscar(this.filtros);
                }
                this.close();
            } catch (error) {
                if (error.response && 422 == error.response.status) {
                    this.errores = error.response.data.errors;
                } else {
                    if (error.response && 403 == error.response.status) {
                        this.$router.push('/403');
                    } else {
                        console.log(error)
                        this.$swal.fire({
                            title: "Algo sucedio!",
                            text: "contacta con el administrador",
                            icon: "error"
                        });
                    }

                }
            }
            this.loadingGuardar = false;
        },
        async trasladar(item){
            this.formTraslado.cbdpId = item.cbdpId;
            this.formTraslado.cobdId = item.cobdId;
            this.dialog2 = true;
        },
        async trasladarPaciente() {
            try {
                this.errores = [];
                this.loadingGuardar = true;
                let data = await this.$apiService.update("cita/basedatospacientes/traslado/"+this.formTraslado.cbdpId, {cobdId:this.formTraslado.cobdId}).then(data => data);
                if (data) {
                        this.$swal.fire({
                            title: "Completado!",
                            text: "Traslado exitoso",
                            icon: "success",
                            confirmButtonText: "Ok"
                        });
                        await this.buscar(this.filtros);
                }
                this.formTraslado = {
                    cbdpId:null,
                    cobdId: null,
                }
                this.dialog2=false;
            } catch (error) {
                if (error.response && 422 == error.response.status) {
                    this.errores = error.response.data.errors;
                } else {
                    if (error.response && 403 == error.response.status) {
                        this.$router.push('/403');
                    } else {
                        console.log(error)
                        this.$swal.fire({
                            title: "Algo sucedio!",
                            text: "contacta con el administrador",
                            icon: "error"
                        });
                    }

                }
            }
            this.loadingGuardar = false;
        },
        async editar(item){
            this.form = item;
            let pacientes = await this.$apiService.index(`sistema/personas/view/${this.form.coppId}/pacientes?tipo=paciente`).then(data => data);
            this.pacientes.pacientes = [pacientes];
            this.isNew = false;
            this.dialog = true;
        },
        async eliminar(id) {
            try {
                if (this.getPermiso("eliminarBasedatos")) {
                    let result = await this.$swal.fire({
                        title: "Estas seguro?",
                        text: "No podras revertir esto!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Si, eliminar!"
                    }).then(result => result.value);
                    if (result) {
                        let data = await this.$apiService.delete("cita/basedatospacientes/delete/" + id + "/").then(data => data);
                        if (data) {
                            this.listaCopia = this.lista = this.lista.filter(
                                e => e.cbdpId != id
                            );
                            this.$swal.fire(
                                "Eliminado!",
                                "ha sido eliminado con exito.",
                                "success"
                            );
                        }
                    }
                }
            } catch (error) {
                this.$swal.fire({
                    title: "Algo sucedio!",
                    text: "contacta con el administrador",
                    icon: "error"
                });
            }

        },

        limpiar() {
            this.form = {
                cbdpTipoEstado: null,

                coppId: null,

                cobdId: this.cobdId,

            };
        },
        close() {
            this.isNew = true;
            this.dialog = false;
            this.limpiar();
        }
    }
};
</script>

<style>

</style>
